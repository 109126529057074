import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as styles from './ProcessJournalsList.module.scss';
import { getSingleJournalUrl } from '../../utilities/permalinks';
import EmptyContent from '../Helpers/EmptyContent';

function ProcessJournalsList(props) {
  const { journals, typeBreadcrumbs } = props;

  const renderEmptyContent = () => (
    <EmptyContent message="No posts found in this category." />
  );

  const renderImage = (image) => {
    const width = image?.localFile?.originalImage?.original?.width;
    const height = image?.localFile?.originalImage?.original?.height;
    if (width / height > 1) {
      // landscape image
      return (
        <>
          <GatsbyImage
            className="d-md-none"
            image={getImage(image.localFile.mobileImageLandscape)}
            alt={image.altText}
          />
          <GatsbyImage
            className="d-none d-md-block"
            image={getImage(image.localFile.desktopImageLandscape)}
            alt={image.altText}
          />
        </>
      );
    }
    // portrait image
    return (
      <>
        <GatsbyImage
          className="d-md-none"
          image={getImage(image.localFile.mobileImagePortrait)}
          alt={image.altText}
        />
        <GatsbyImage
          className="d-none d-md-block"
          image={getImage(image.localFile.desktopImagePortrait)}
          alt={image.altText}
        />
      </>
    );
  };

  const renderContent = () => {
    if (journals.length) {
      return (
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <ul className={styles.processJournalsList}>
                {journals.map((journal) => {
                  const image = journal.featuredImage.node;
                  return (
                    <li key={journal.id}>
                      <Link
                        to={getSingleJournalUrl(journal.slug)}
                        title={journal.title}
                        state={{ breadcrumbs: typeBreadcrumbs }}
                      >
                        {renderImage(image)}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      );
    }

    return renderEmptyContent();
  };

  return <>{renderContent()}</>;
}
ProcessJournalsList.defaultProps = {
  journals: [],
  typeBreadcrumbs: null,
};
ProcessJournalsList.propTypes = {
  journals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      featuredImage: PropTypes.shape({
        node: PropTypes.shape({
          altText: PropTypes.string,
          localFiel: PropTypes.shape({
            originalImage: PropTypes.shape({}).isRequired,
            mobileImagePortrait: PropTypes.shape({}).isRequired,
            mobileImageLandscape: PropTypes.shape({}).isRequired,
            desktopImagePortrait: PropTypes.shape({}).isRequired,
            desktopImageLandscape: PropTypes.shape({}).isRequired,
          }),
        }),
      }),
    }),
  ),
  typeBreadcrumbs: PropTypes.arrayOf(PropTypes.shape({})),
};
export default ProcessJournalsList;

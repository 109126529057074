import { useEffect, useContext } from 'react';
import { ThemeContext } from '../utilities/ThemeContext';

const usePageStyles = (pageStyles) => {
  const styleId = 'pageStyles';
  const { theme, setTheme } = useContext(ThemeContext);

  // Switch theme in case of home page
  useEffect(() => {
    if (pageStyles?.textColor && theme === 'light') {
      setTheme('dark');
    } else if (!Object.prototype.hasOwnProperty.call(pageStyles, 'textColor')) {
      setTheme('light');
    }
  }, [theme]);

  useEffect(() => {
    if (pageStyles?.backgroundColor || pageStyles?.textColor) {
      const styleTag = document.createElement('style');
      const backgroundColor = pageStyles?.backgroundColor
        ? `--tomato-page-background-color: ${pageStyles.backgroundColor};`
        : '';

      // Hardcoding text color to black - Disabled
      const textColor = pageStyles?.textColor
        ? `--tomato-page-text-color: ${pageStyles.textColor}`
        : '';
      // const textColor = pageStyles?.textColor
      //   ? '--tomato-page-text-color: #000'
      //   : '';
      styleTag.innerHTML = `
        :root {
            ${backgroundColor}
            ${textColor}
        }
        `;
      styleTag.id = styleId;
      document.head.appendChild(styleTag);

      return () => {
        document.head.removeChild(styleTag);
      };
    }

    return null;
  }, []);
};

export default usePageStyles;

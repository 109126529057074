import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

function EmptyContent(props) {
  const { message, showLink } = props;

  return (
    <div className="empty-content">
      {/* eslint-disable-next-line react/no-danger */}
      <h2 dangerouslySetInnerHTML={{ __html: message }} />
      {showLink && <Link to="/">Back to Home</Link>}
    </div>
  );
}

EmptyContent.defaultProps = {
  message: 'The page you are trying to access, does not exist.',
  showLink: true,
};
EmptyContent.propTypes = {
  message: PropTypes.string,
  showLink: PropTypes.bool,
};
export default EmptyContent;

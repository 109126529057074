import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import usePageStyles from '../hooks/usePageStyles';
import HeaderSpacer from '../components/Helpers/HeaderSpacer';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import ProcessJournalsList from '../components/JournalsList/ProcessJournalsList';
import { getTypeArchiveUrl } from '../utilities/permalinks';

function Process(props) {
  const {
    data: {
      type,
      processJournals: { nodes: journals },
    },
  } = props;

  usePageStyles({ backgroundColor: '#929598' });

  return (
    <>
      <Seo post={type} />
      <HeaderSpacer />
      <Breadcrumbs data={[{ label: type.name }]} />
      <ProcessJournalsList
        journals={journals}
        typeBreadcrumbs={[
          { label: type.name, url: getTypeArchiveUrl(type.slug) },
        ]}
      />
    </>
  );
}
Process.propTypes = {
  data: PropTypes.shape({
    type: PropTypes.shape({
      id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
    processJournals: PropTypes.shape({
      nodes: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    typeId: PropTypes.string.isRequired,
  }).isRequired,
};
export default Process;

export const query = graphql`
  query processQuery($typeId: String) {
    type: wpType(id: { eq: $typeId }) {
      seo {
        ...YoastTaxonomySEOContent
      }
      id
      slug
      name
    }
    processJournals: allWpJournal(
      filter: {
        types: { nodes: { elemMatch: { id: { eq: $typeId } } } }
        featuredImageId: { ne: null }
      }
    ) {
      nodes {
        ...ProcessJournalsListingFields
      }
    }
  }
`;
